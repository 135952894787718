import { Ref, readonly, ref, computed, unref } from 'vue'
// import { useAuth0 } from '@auth0/auth0-vue'
import {
  calculateSummary,
  fetchDataUrl,
  markupToHtml,
  toCents,
  formatCurrency,
  useTemplateFile,
  useDetailsContext,
  filterLineItems,
} from '@/utils'
import { useDownloadPdf, getPdfFilename } from '@/composables/downloadPdf'
import { mergeAdvanced } from 'object-merge-advanced'
import type { Invoice } from '@@/types'

export function usePreviewContext(
  invoice: Ref<Invoice>,
  contextOverride?: Ref<Record<string, unknown>> | Record<string, unknown>
) {
  const lineItems = computed(() =>
    filterLineItems(
      invoice.value.lineItems ?? [],
      invoice.value.workflowId,
      invoice.value.workflowStageId
    )
  )

  const invoiceContributions = computed(() => [
    ...(invoice.value.shipping ? [invoice.value.shipping] : []),
  ])

  const invoiceSummary = computed(() =>
    calculateSummary(
      lineItems.value.pricedLineItems,
      invoiceContributions.value,
      invoice.value.taxPercentage,
      invoice.value.disableArtworkItemPrice,
      invoice.value.excludeDiscountsFromSubtotal
    )
  )

  const paymentsTotal = computed(() => {
    return invoice.value.payments?.reduce((acc, p) => (acc += p.amount), 0) ?? 0
  })

  const detailsContext = useDetailsContext(invoice)

  return computed(() =>
    mergeAdvanced(
      {
        template: {
          showCurrencyCode: invoice.value.templateOverrides?.showCurrencyCode,
          showCurrencySymbol:
            invoice.value.templateOverrides?.showCurrencySymbol,
          showPayments:
            invoice.value.templateOverrides?.showPayments &&
            invoice.value.payments?.length,
          showDiscountTotal: invoice.value.templateOverrides?.showDiscountTotal,
          additionalHeading: invoice.value.templateOverrides?.additionalHeading,
          hideLineItemTax: invoice.value.templateOverrides?.hideLineItemTax,
          useFullDate: invoice.value.templateOverrides?.useFullDate,
        },
      },
      {
        editing: true, // show header, footer and page break borders
        ...invoice.value,
        id: invoice.value.id,
        title: invoice.value.title,
        invoiceNumber: invoice.value.invoiceNumber,
        currency: invoice.value.currency,
        currencyOptions: {
          currency: invoice.value.currency || 'USD',
          showCurrencySymbol:
            invoice.value.templateOverrides?.showCurrencySymbol,
        },
        date: invoice.value.date,
        dueDate: invoice.value.dueDate,
        locale: 'en-US',
        discountTotal: toCents(invoiceSummary.value.discount),
        tax: toCents(invoiceSummary.value.tax),
        subTotal: toCents(invoiceSummary.value.subTotal),
        grandTotal: toCents(invoiceSummary.value.total),
        remainingBalance: toCents(
          invoiceSummary.value.total - paymentsTotal.value
        ),
        remainingDeposit:
          invoice.value.depositAmount &&
          invoice.value.depositAmount > toCents(paymentsTotal.value)
            ? invoice.value.depositAmount - toCents(paymentsTotal.value)
            : 0,
        billingAddress: {
          ...invoice.value.billingAddress,
        },
        shippingAddress: {
          ...invoice.value.shippingAddress,
        },
        shipping: {
          price: 0,
          description: '',
          currency: 'USD',
          discountAmount: 0,
          taxAmount: 0,
          ...(invoice.value.shipping
            ? {
                ...invoice.value.shipping,
                price: toCents(invoice.value.shipping.price),
                discountAmount: toCents(invoice.value.shipping.discountAmount),
                discountedPrice: toCents(
                  invoice.value.shipping.price -
                    invoice.value.shipping.discountAmount
                ),
                taxAmount: toCents(
                  (invoice.value.shipping.price -
                    invoice.value.shipping.discountAmount) *
                    invoice.value.taxPercentage
                ),
              }
            : {}),
        },
        lineItems: lineItems.value.filteredLineItems.map((item) => ({
          ...item,
          description: markupToHtml(
            item.description ?? invoice.value.template?.itemCaption ?? '',
            {
              ...item.meta,
              price: `${formatCurrency(toCents(item.price), {
                currency: item.currency || 'USD',
                showCurrencySymbol:
                  invoice.value.templateOverrides?.showCurrencySymbol,
              })}${
                invoice.value.templateOverrides?.showCurrencyCode
                  ? ' ' + item.currency
                  : ''
              }`,
            }
          ),
          price: toCents(item.price),
          discountAmount: toCents(item.discountAmount),
          discountedPrice: toCents(item.price - item.discountAmount),
          taxAmount: toCents(
            (item.price - item.discountAmount) * invoice.value.taxPercentage
          ),
        })),
        payments:
          invoice.value.payments?.map((payment) => ({
            ...payment,
            amount: toCents(payment.amount),
          })) ?? [],
        details: invoice.value.details?.map((detail) => ({
          ...detail,
          content: markupToHtml(detail.content, detailsContext.value),
        })),
        transactionFee: toCents(lineItems.value.transactionFeeItem?.price || 0),
        ...unref(contextOverride),
      },
      {
        ignoreKeys: [
          'showCurrencyCode',
          'showCurrencySymbol',
          'showPayments',
          'showDiscountTotal',
          'additionalHeading',
          'hideLineItemTax',
          'useFullDate',
        ],
      }
    )
  )
}

export function useDownloadPreview(
  invoice: Ref<Invoice>,
  contextOverride?: Ref<Record<string, unknown>> | Record<string, unknown>,
  pageFormat?: string
) {
  const previewContext = usePreviewContext(invoice, contextOverride)
  // const { getAccessTokenSilently } = useAuth0()
  const downloading = ref(false)

  const download = async (templateLayout?: string) => {
    // header/footer cannot access the internet so send base64 encoded urls
    const headerFooterContext = {
      headerImageUrl: invoice.value.template?.headerUrl
        ? await fetchDataUrl(invoice.value.template?.headerUrl)
        : '',
      headerAlignment: invoice.value.template?.headerAlignment ?? 'left',
      headerSize: invoice.value.template?.headerSize ?? 'medium',

      footerImageUrl: invoice.value.template?.footerUrl
        ? await fetchDataUrl(invoice.value.template?.footerUrl)
        : '',
      footerAlignment: invoice.value.template?.footerAlignment ?? 'left',
    }

    downloading.value = true

    // const accessToken = await getAccessTokenSilently()
    // use the override template if it exists
    // fallback to invoice template, then default
    const templateRaw = useTemplateFile(
      templateLayout || invoice.value.template?.layoutFilename || 'default'
    )
    const headerRaw = invoice.value.template?.headerLayoutFilename
      ? useTemplateFile(invoice.value.template?.headerLayoutFilename)
      : ''
    const footerRaw = invoice.value.template?.footerLayoutFilename
      ? useTemplateFile(invoice.value.template?.footerLayoutFilename)
      : ''

    // PDF Filename
    const downloadPdf = useDownloadPdf(templateRaw, headerRaw, footerRaw, pageFormat)
    const title = getPdfFilename(
      invoice.value.title ?? '',
      invoice.value.workflowStage?.name ?? ''
    )
    await downloadPdf(title, {
      context: previewContext.value,
      headerFooterContext,
      // authorization: `Bearer ${accessToken}`,
    })

    downloading.value = false
  }

  return { previewContext, download, downloading: readonly(downloading) }
}
