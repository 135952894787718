import { LineItem, InvoiceSummary, InvoiceContribution } from '@@/types'
import roundToDecimal from '../roundToDecimal'
import calculateTax from './calculateTax'

const calculateNetPrice = (item: LineItem) => {
  return item.price
    ? item.price > 0
      ? item.price > item.discountAmount
        ? item.price - item.discountAmount
        : 0
      : item.price
    : 0
}

const calculateDiscount = (item: LineItem) => {
  return item.price
    ? item.price > 0
      ? item.price > item.discountAmount
        ? item.discountAmount
        : item.price
      : 0
    : 0
}

export default (
  lineItems: LineItem[],
  contribs: LineItem[],
  taxRate = 0,
  excludeArtworkItems = false,
  excludeDiscountsFromSubtotal = false
): InvoiceSummary => {
  const contribItems: InvoiceContribution[] = contribs.map((contrib) => ({
    id: contrib.id,
    amount: contrib.price ?? 0,
  }))

  // sum of contribution item prices
  const contribsTotal = contribs.reduce<number>(
    (acc, contrib) => acc + contrib.price ?? 0,
    0
  )

  // Exclude artwork inventory items when flag is on
  if (excludeArtworkItems) {
    lineItems =  lineItems.filter((item) => item.inventory == null)
  }
  
  const itemsTotal = lineItems
    .filter((item) => item.specialCase !== 'credit card')
    .reduce<number>((acc, item) => {
      return acc + item.price ?? 0
    }, 0)

  const itemsDiscount = lineItems
    .filter((item) => item.specialCase !== 'credit card')
    .reduce((acc, item) => acc + calculateDiscount(item), 0)

  const contribsDiscount = contribs.reduce(
    (acc, item) => acc + calculateDiscount(item),
    0
  )

  const discountTotal = itemsDiscount + contribsDiscount

  const itemsNetPrice = lineItems.filter((item) => item.isTaxed && item.price >= 0).reduce((acc, item) => acc + calculateNetPrice(item), 0)
  const contribsNetPrice = contribs.filter((item) => item.isTaxed && item.price >= 0).reduce((acc, item) => acc + calculateNetPrice(item), 0)
  const taxTotal = Math.max(calculateTax(itemsNetPrice + contribsNetPrice, taxRate), 0)
  const subTotal = excludeDiscountsFromSubtotal ? itemsTotal - itemsDiscount : itemsTotal

  const ccFee =
    lineItems.find((lineItem) => lineItem.specialCase === 'credit card')
      ?.price || 0

  const total = excludeDiscountsFromSubtotal 
  ? roundToDecimal(subTotal + ccFee + contribsTotal  + taxTotal)
  : roundToDecimal(subTotal + ccFee + contribsTotal - discountTotal + taxTotal)

  return {
    contribs: contribItems,
    contribsTotal,
    subTotal,
    tax: taxTotal,
    total,
    discount: discountTotal,
    totalMinusFees: total - ccFee,
  }
}